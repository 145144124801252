import { addRule } from 'redux-ruleset'
import { SET_SEARCH_VALUE } from 'modules/ui/const'
import { FETCH_SUCCESS } from 'modules/listing/const'
import { BURGER_NAVI_CLICK } from 'theme/app/Header/events'
import { push as dlPushFn } from 'features/tracking/datalayer'
import * as searchEvents from 'theme/app/Header/SearchField/events'
import getPageInfo from 'utils/getPageInfo'
import { VIEW_SEARCH_RESULT_PDP } from 'theme/app/Header/SearchField/events'

let preLocation: Location | null = null

/**
 * WHEN the user navigates to a new route
 * THEN we save the previous location
 */
addRule({
  id: 'search/PRELOCATION',
  target: `navigation/LOCATION_CHANGED`,
  output: '#dl-event',
  concurrency: 'FIRST',
  consequence: (action) => {
    preLocation = action.meta.prevLocation || null
  }
})

addRule({
  id: 'feature/NAVIGATE_TO_PDP',
  target: VIEW_SEARCH_RESULT_PDP,
  output: '#navigate',
  consequence: (action) =>
    dlPushFn({
      event: 'internalSearch',
      eventname: 'view_search_result_pdp',
      search_keyword: action.payload.search,
      search_initiator: action.payload.pageType,
      search_initiator_url: action.payload.url
    })
})

addRule({
  id: 'header/SEARCH',
  target: SET_SEARCH_VALUE,
  output: '#dl-event',
  addUntil: function* (next, { context }) {
    yield next(
      [searchEvents.SEARCH_BUTTON_CLICK, searchEvents.INPUT_TYPE_ENTER],
      (action) => {
        if (action.type == 'ProductWidget/SEARCH_BUTTON_CLICK') {
          context.set('search_click_through', 'Submit Button')
        } else if (action.type == searchEvents.INPUT_TYPE_ENTER) {
          context.set('search_click_through', 'Input Field Enter')
        }
      }
    )
    return 'REMOVE_RULE'
  },
  consequence: (action, { addRule, context }) => {
    const search_click_through = context.get('search_click_through')
    addRule('waitForFetch', { search_click_through })
  },
  subRules: {
    waitForFetch: {
      target: [FETCH_SUCCESS],
      output: '#dl-event',
      addOnce: true,
      consequence: (action, { context }) => {
        if (action.meta.recordId === 'search') {
          const numHits = action.payload.nbHits
          const pageInfo = getPageInfo(preLocation?.pathname || '')
          dlPushFn({
            event: 'internalSearch',
            eventname: 'view_search_results',
            search_keyword: action.meta.filterValues.query,
            search_results: numHits,
            search_click_through: context.get('search_click_through') || '',
            search_initiator: pageInfo.pageType,
            search_initiator_url: preLocation?.href
          })
          dlPushFn({
            event: 'genericEvent',
            eventname: 'productCount',
            category: 'productCount',
            action: `Search: ${action.meta.filterValues.query}`,
            label: numHits,
            value: numHits,
            nonInteraction: 'false',
            productCount: numHits
          })
        }
      }
    }
  }
})

addRule({
  id: 'dl/BURGER_NAVI_CLICK',
  target: [BURGER_NAVI_CLICK],
  output: '#dl-event',
  consequence: () => {
    dlPushFn({
      event: 'genericEvent',
      eventname: 'mobile_burger_icon',
      category: 'Engagement',
      action: 'mobile_burger_icon',
      value: '0',
      nonInteraction: 'false'
    })
  }
})
