import * as t from './types'
import config from 'config'
import getCookieByName from 'utils/getCookieByName'

export const API_KEY = config.modules.dyHybrid.apiKey

export function createBody(props: t.DyContext) {
  const body: any = {
    user: {
      dyid: props._dyid_server,
      dyid_server: props._dyid_server,
      active_consent_accepted: props.consentStatus === 'ACCEPTED'
    },
    context: {
      device: {
        userAgent: window.navigator.userAgent
      }
    },
    session: {
      dy: props._dyjsession
    }
  }
  if (props.consentStatus === 'ACCEPTED') {
    if (props._dy_cs_gcg) {
      body.context.pageAttributes = {
        globalControlGroup: props._dy_cs_gcg
      }
    }
  }

  body.context.pageAttributes = {
    ...body.context.pageAttributes,
    customerType:
      getCookieByName(config.slugifyLocale + '-channel')?.toUpperCase() ||
      'undecided',
    returningUser: props.isRetuningUser
  }

  return body
}

/** @firescoutMockFn dy.fetch */
export async function dyFetch(url: string, options: RequestInit) {
  const result = await fetch(url, options).then((r) =>
    r.status !== 204 ? r.json() : null
  )
  return result
}
