import * as React from 'react'
import styled from 'styled-components'
import theme from 'theme'
import ArrowRight from 'assets/arrow-right.svg'
import DoubleArrowRight from 'assets/double-arrow-right.svg'
import Link from 'theme/atoms/Link'

type Props = {
  isActive: boolean
  hasChildren: boolean
  categoryName: string
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void
  'data-cy-handle': string
  to?: string | null
  hasFullWidth?: boolean
  isBold?: boolean
  urlPath?: string
  title: string
}

function CategoryItem(props: Props) {
  return (
    <Wrapper
      className="CategoryItem"
      $isActive={props.isActive}
      onClick={props.onClick}
      data-cy-handle={props['data-cy-handle']}
      to={props.to}
      $hasFullWidth={props.hasFullWidth}
      $isBold={props.isBold}
      title={props.title}
    >
      {props.hasChildren && (
        // eslint-disable-next-line
        <Link
          data-cy-state="passive-category-link"
          to={props.urlPath}
          onClick={(e) => e.preventDefault()}
          title={props.title}
        >
          {props.categoryName}
        </Link>
      )}
      {!props.hasChildren && (
        // eslint-disable-next-line @kaminrunde/firescout/jsx-expression-needs-state
        <span>{props.categoryName}</span>
      )}

      {props.isActive ? (
        // eslint-disable-next-line @kaminrunde/firescout/jsx-expression-needs-state
        <DoubleArrowRight className="double-chevron" />
      ) : props.hasChildren ? (
        <ArrowRight className="chevron" />
      ) : null}
    </Wrapper>
  )
}

const Wrapper = styled(Link)<{
  $isActive: Props['isActive']
  $hasFullWidth: Props['hasFullWidth']
  $isBold: Props['isBold']
}>`
  display: grid;
  grid-template-columns: auto auto;
  ${(p) => !p.$hasFullWidth && 'width: 325px'};
  height: 38px;
  padding: ${theme.spacing('xxs')} ${theme.spacing('m')};
  align-items: center;
  gap: ${theme.spacing('xs')};
  align-self: stretch;
  ${(p) => p.$isBold && 'font-weight: 700 !important;'};
  ${theme.ty('rc-2xl', null, 'rc-base')}
  ${(p) =>
    p.$isActive &&
    `
        background: ${theme.colors.shade.primaryUltraBright};
        ${theme.ty('rc-2xl', '700', 'rc-base')}
        text-decoration: underline;
    `}
    > span {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:hover {
    background: ${theme.colors.shade.primaryUltraBright};
    cursor: pointer;
  }

  > .chevron {
    justify-self: flex-end;
    width: 24px;
    height: 14px;
  }
  > .double-chevron {
    justify-self: flex-end;
    width: 24px;
    height: 24px;
  }
`

export default CategoryItem
