import * as React from 'react'
import config from 'config'
import Button from 'theme/atoms/Button'
import Input from 'theme/atoms/Input'
import Faktor from 'assets/factor.svg'
import { useIntentLayer } from 'modules/ui'
import { push } from 'features/tracking/datalayer'
import Close from 'assets/menu-close.svg'
import { invalidMail } from './events'
import useTranslations from 'hooks/useTranslations'
import Link from 'theme/atoms/Link'
import { Props } from './types'
import { styled } from 'styled-components'
import theme from 'theme'
import useAccount from 'hooks/account/useAccount'
import useNewsletter from 'hooks/useNewsletter'

export default function NewsletterIntentContent(props: Props) {
  const account = useAccount()
  const newsletter = useNewsletter()
  const customerType = props.isB2B ? ('b2b' as const) : ('b2c' as const)
  const t = useTranslations<'app-NewsletterIntentLayer'>()
  const discountReductionPath =
    config.modules.cloudinary.newsletter_intentlayer_picture
  const intentLayer = useIntentLayer()

  const [error, setError] = React.useState<string | null>(null)
  const [email, setEmail] = React.useState('')

  const handleClose = () => {
    push({
      event: 'genericEvent',
      eventname: 'newsletter_layer',
      action: 'close'
    })

    intentLayer.hideIntentLayer()
  }

  const handleSubmit = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    if (!emailRegex.test(email)) {
      setError(t('mail_error_text'))
      invalidMail()
      return
    }
    newsletter.createContactWithOptIn({
      channel: account.channel,
      email: email,
      subscriptionSource: 'Newsletterlayer'
    })
    intentLayer.registerNewsletterNL()
  }

  return (
    <Wrapper className={'NewsletterIntentContent ' + props.className}>
      <div className="modal">
        <div className="image-container">
          <img
            src={
              props.isB2B
                ? 'https://res.cloudinary.com/lusini/image/upload/f_auto,q_70/v1/application/NewsletterPopupAB/plrsiebtw2qmcrmxeqjc'
                : 'https://res.cloudinary.com/lusini/image/upload/f_auto,q_70/v1/application/NewsletterPopupAB/emgqvpuormd6si2henol'
            }
            alt={'image'}
          />
          <img
            className="reduction"
            src={discountReductionPath}
            alt="voucher"
          />
        </div>

        <div className="info-container">
          <div data-cy-handle="close" className="close" onClick={handleClose}>
            <Close />
          </div>
          <h2>{t(`${customerType}_benefits_headline_label`)}</h2>
          <ul className="benefits">
            <li>
              <Faktor />{' '}
              <span>
                {t(`${customerType}_benefit_text1`, {
                  MONEY_VALUE: '-',
                  GET: '-',
                  __transformers: {
                    MONEY_VALUE: (_, vars) => (
                      <strong>{vars.MONEY_VALUE_label}</strong>
                    ),
                    GET: (_, vars) => <strong>{vars.GET_label}</strong>
                  }
                })}
              </span>
            </li>

            <li>
              <Faktor />
              {t(`${customerType}_benefit_text2`, {
                CURRENT_NEWS_TIPS: '-',
                __transformers: {
                  CURRENT_NEWS_TIPS: (_, vars) => (
                    <strong> {vars.CURRENT_NEWS_TIPS_label} </strong>
                  )
                }
              })}
            </li>

            <li>
              <Faktor />
              {t(`${customerType}_benefit_text3`, {
                EXCLUSIVE_OFFERS: '-',
                __transformers: {
                  EXCLUSIVE_OFFERS: (_, vars) => (
                    <strong> {vars.EXCLUSIVE_OFFERS_label} </strong>
                  )
                }
              })}
            </li>
          </ul>

          <Input
            type="email"
            className="checkout-input"
            data-cy-handle="email-input"
            value={email}
            error={error}
            placeholder={t('mail_placeholder_text') || ''}
            onChange={(val) => {
              setEmail(val)
              setError(null)
            }}
          />
          <Button
            variation="primary"
            data-cy-handle="onclick"
            onClick={handleSubmit}
          >
            {t(`${customerType}_benefits_submit_btn_label`)}
          </Button>
          <p className="info-text">
            <span>
              {t(`${customerType}_benefits_info_text`, {
                PRIVACY: '-',
                __transformers: {
                  PRIVACY: (_, vars) => (
                    <Link to={vars.PRIVACY_link}>{vars.PRIVACY_label}</Link>
                  )
                }
              })}
            </span>
          </p>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: ${theme.zIndex.theme_app_b2xmodal_Overlay};
  background: rgba(0, 0, 0, 0.5);

  > .modal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: white;
    width: 100%;
    text-align: center;
    display: flex;

    > .image-container {
      max-width: 50%;
      display: none;
      position: relative;
      > img:first-child {
        width: 100%;
        height: 100%;
      }
      > img.reduction {
        position: absolute;
        top: ${theme.spacing('m')};
        right: ${theme.spacing('m')};
        width: 6.25rem;
        height: 6.25rem;
      }
    }
    > .info-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: ${theme.spacing('m')};
      gap: ${theme.spacing('m')};
      > .close {
        position: absolute;
        top: ${theme.spacing('m')};
        right: ${theme.spacing('m')};
        cursor: pointer;
      }
      > h2 {
        ${theme.ty('rc-2xl', '700', 'rc-3xl')}
        margin-top:${theme.spacing('m')};
        text-align: left;
      }
      > .benefits {
        text-align: left;

        ${theme.ty('rc-s', '400', 'rc-base')}

        > li {
          margin-bottom: ${theme.spacing('xs')};
          &:last-child {
            margin-bottom: 0;
          }
          display: flex;
          align-items: start;
          padding: 0;
          > svg {
            margin-right: 0.75rem;
            min-width: 1rem;
            margin-top: 0.1875rem;
          }
        }
      }

      > .info-text {
        padding: 0;
        margin: 0;
        display: flex;
        flex: 1;
        align-items: end;
        ${theme.ty('rc-xs', '400', 'rc-s')}
        text-align: left;
        > span {
          display: block;
          > span {
            > span {
              > a {
                color: ${theme.colors.black};
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
    @media (min-width: ${theme.ms.MD}px) {
      max-width: 38.25rem;
      max-height: 28.125rem;
      > .info-container {
        padding: ${theme.spacing('l')};
        > .close {
          position: absolute;
          top: ${theme.spacing('l')};
          right: ${theme.spacing('l')};
          cursor: pointer;
        }
      }
    }
    @media (min-width: ${theme.ms.LG}px) {
      max-width: 53.75rem;
      max-height: 33.75rem;
      > .info-container {
        padding: ${theme.spacing('l')};
        max-width: 50%;
      }
      > .image-container {
        display: block;
      }
    }
  }
`
